import React from "react"
import Layout from "@components/layout"
import Seo from "@components/seo"
import SolutionsSidebar from "@components/solutionsSidebar"
import { graphql } from "gatsby"
const HelpDesk = ({ data }) => (
  <Layout>
    <Seo title="Help Desk" description={data.site.siteMetadata.description} />
    <div className="page-headline">
      <div className="container">
        <h6 className="text-white-50 text-uppercase">Solutions</h6>
        <h1>Help Desk</h1>
      </div>
    </div>
    <div className="page-content bg-light">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-7 col-lg-8">
            <p>
              When a network system or device isn’t working properly, your
              people are not able to do their work or deliver services to your
              customers. We understand and provide you with immediate access to
              a technical support team to get the issues resolved. Our staff
              consists of professional and knowledgeable technicians who are
              standing by to resolve issues in a timely manner, the first time.
              Tickets may be opened for support requests via phone, email or
              through online portal.
            </p>

            <h4>Our Help Desk Components:</h4>
            <ul>
              <li>Unlimited Remote Support</li>
              <li>24/7 Network Monitoring</li>
              <li>Server and network maintenance and support</li>
              <li>Onsite support</li>
              <li>Antivirus & Malware Protection</li>
              <li>Web Security Management</li>
              <li>BDR Monitoring and Management</li>
            </ul>

            <p>
              Knowledge is power. We maintain an extensive library of
              documentation regarding each client and network environment.
              Technicians have access to the vital information they need to make
              decisions and solve problems without having to do extensive
              research.
            </p>
          </div>
          <div className="col-md-4 col-lg-3">
            <SolutionsSidebar />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default HelpDesk

export const HelpDeskQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
